<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
      >
        <v-row justify="center">
          <v-col
            md="4"
            cols="12"
          >
            <v-text-field
              v-model="dataComputed.name"
              :label="t('sports.name')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-textarea
              v-model="dataComputed.description"
              :label="t('sports.description')"
              outlined
              rows="2"
              :rules="[]"
            ></v-textarea>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="dataComputed.status"
              dense
              outlined
              :label="t('status.status')"
              :items="statusOptions"
              item-text="text"
              item-value="value"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            />
          </v-col>

          <v-col
            cols="12"
          >
            <v-divider />
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model.number="dataComputed.bkf"
              :label="$t('sports.bkf')"
              class="text-body-1"
              outlined
              dense
              :rules="[required, integerValidator]"
              :readonly="option === 2"
              @keypress="onlyNumbers"
            />
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model.number="dataComputed.df"
              :label="$t('sports.df')"
              class="text-body-1"
              outlined
              dense
              :rules="[required, integerValidator]"
              :readonly="option === 2"
              @keypress="onlyNumbers"
            />
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model.number="dataComputed.nb"
              :label="$t('sports.nb')"
              class="text-body-1"
              outlined
              dense
              :rules="[required, integerValidator]"
              :readonly="option === 2"
              @keypress="onlyNumbers"
            />
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model.number="dataComputed.nc"
              :label="$t('sports.nc')"
              class="text-body-1"
              outlined
              dense
              :rules="[required, integerValidator]"
              :readonly="option === 2"
              @keypress="onlyNumbers"
            />
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model.number="dataComputed.mpf"
              :label="$t('sports.mpf')"
              class="text-body-1"
              outlined
              dense
              :rules="[required, integerValidator]"
              :readonly="option === 2"
              @keypress="onlyNumbers"
            />
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model.number="dataComputed.bdr"
              :label="$t('sports.bdr')"
              class="text-body-1"
              outlined
              dense
              :rules="[required, integerValidator]"
              :readonly="option === 2"
              @keypress="onlyNumbers"
            />
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model="dataComputed.drf"
              :label="$t('sports.drf')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required, between(dataComputed.drf, 0, 1), regexValidator(dataComputed.drf, '^[0-9]{0,99}(\.?[0-9]{0,2})?$', 'Incorrect format')]"
              @input="e => {
                if (!charUnique(e, '.') || regexValidator(dataComputed.drf, '^[0-9]{0,99}(\.?[0-9]{0,2})?$', 'Incorrect format') !== true) {
                  dataComputed.drf = null
                }
              }"
              @keypress="onlyNumberDot"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-select
              v-model="dataComputed.drp"
              dense
              outlined
              :label="$t('sports.drp')"
              :items="drpOptions"
              item-text="text"
              item-value="value"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            />
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model.number="dataComputed.smsd"
              :label="$t('sports.smsd')"
              class="text-body-1"
              outlined
              dense
              :rules="[required, integerValidator]"
              :readonly="option === 2"
              @keypress="onlyNumbers"
            />
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model.number="dataComputed.ssk"
              :label="$t('sports.ssk')"
              class="text-body-1"
              outlined
              dense
              :rules="[required, integerValidator]"
              :readonly="option === 2"
              @keypress="onlyNumbers"
            />
          </v-col>

          <options-buttom-tab
            :tab-number="tab"
            :is-mode-save-edit="option !== 2"
            :no-save="false"
            :info-required="true"
            :no-cancel="option === 3"
            :no-arrow="true"
            @onCancel="onCancelButton"
            @onNextTab="changeTab"
            @onSave="onSaveAction"
          >
          </options-buttom-tab>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mdiAlertOutline, mdiCloudUploadOutline, mdiMenuDown } from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import { required, emailValidator, integerValidator, charUnique, regexValidator, minNumber, maxNumber, between } from '@core/utils/validation'
import { onlyNumbers, onlyNumberDot } from '@core/utils/functionsHelpers'
import { useUtils } from '@core/libs/i18n'
import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'

export default {
  components: {
    OptionsButtomTab,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
    facilities: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const form = ref(null)
    const dataComputed = computed(() => props.dataParams)

    const statusOptions = computed(() => [
      { text: t('status.active'), value: 'A' },
      { text: t('status.inactive'), value: 'I' },
    ])

    const drpOptions = computed(() => [
      { text: t('billing_cycle_types.daily'), value: 1 },
      { text: t('billing_cycle_types.weekly'), value: 7 },
      { text: t('billing_cycle_types.monthly'), value: 30 },
      { text: t('billing_cycle_types.yearly'), value: 365 },
    ])

    const onSaveAction = () => {
      if (form.value.validate()) {
        console.log('🚀 ~ onSaveAction ~ dataComputed.value:', dataComputed.value)

        emit('save', dataComputed.value)
      }
    }

    const onCancelButton = () => {
      form.value.reset()
    }

    const changeTab = tab => {
      if (form.value.validate()) {
        emit('changeTab', tab, true, dataComputed.value)
      }
    }

    return {
      // data
      form,

      // computed
      dataComputed,
      statusOptions,
      drpOptions,

      // methods
      onCancelButton,
      changeTab,
      onSaveAction,

      // validations
      required,
      emailValidator,
      integerValidator,
      charUnique,
      regexValidator,
      minNumber,
      maxNumber,
      between,
      onlyNumbers,
      onlyNumberDot,

      // i18n
      t,

      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiMenuDown,
      },
    }
  },
}
</script>
